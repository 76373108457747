
import React, { useState } from 'react';
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage } from '@ionic/react';
import HBIcon from '../components/HBIcon';
import { IconNameEnum } from '../components/HBIcon/HBIcon';
import './EarnTab.scss';
import { informationCircle } from 'ionicons/icons';
import RewardModal from '../components/RewardModal';
import { usePathname } from '../hooks';

interface Reward {
  id: number;
  title: string;
  subtitle: string;
  icon: IconNameEnum;
  description: string;
  link?: string
}

const rewards: Reward[] = [
  {
    id: 1,
    title: 'OG Collection',
    subtitle: 'Become a founding partner in HiveBits',
    icon: IconNameEnum.Nft,
    description: 'By participating in the OG Collection, you\'ll have the opportunity to secure a unique role in our project. Stay tuned to learn how you can become a partner in HiveBits and be part of our mission.',
    link: 'https://dapp.hivebits.io'
  },
  {
    id: 2,
    title: 'Beehive Collection',
    subtitle: 'Own your very own digital beehive',
    icon: IconNameEnum.Beehive,
    description: 'The Beehive Collection offers a chance to own a piece of our hive ecosystem. Holders will play a key role in supporting real-world hives, and we’ll reveal how soon. Keep an eye out for exciting updates!'
  },
  {
    id: 3,
    title: 'Airdrop',
    subtitle: 'Earn rewards for your engagement',
    icon: IconNameEnum.Airdrop,
    description: 'Collect points while interacting with the app, and be rewarded through an exclusive airdrop. The more you engage, the more you earn. Details on the airdrop mechanics are coming soon, so stay active!'
  },
]

const RewardsTab: React.FC = () => {

  const [selectedReward, setSelectedReward] = useState<Reward>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectRewardKey, setSelectedRewardKey] = useState(Date.now().toString());

  const pathname = usePathname();

  if (!pathname.startsWith('/rewards')) return;

  return (
    <IonPage className='earn-tab'>
      <IonContent fullscreen>
        <div className='flex flex-col items-center mt-16'>
          <div className='mb-8 coin-icon animate__animated animate__slideInDown'>
            <HBIcon name={IconNameEnum.Cup} size={92} />
          </div>

          <div className='font-bold text-3xl mb-8 animate__animated animate__headShake'>Why to play?</div>

          <div className='text-center mb-8'>
            <div className='text-zinc-400 mb-2'>Listing date</div>
            <div className='text-3xl'><IonLabel color='primary'>{new Date('2025-01-30').toLocaleDateString()}</IonLabel></div>
          </div>


          <IonList className='list w-full  p-4'>
            {rewards.map((r, index) => (
              <IonItem key={index} className='rounded-md mb-4' onClick={() => {
                setSelectedReward(r);
                setSelectedRewardKey(Date.now().toString())
                setIsModalOpen(true)
              }}>
                <HBIcon name={r.icon} size={48} className='mr-4' />
                <div>
                  {r.title}
                  <div className='text-xs text-zinc-400'>{r.subtitle}</div>
                </div>

                <IonIcon slot='end' icon={informationCircle} color='medium' />
              </IonItem>
            ))}
          </IonList>

        </div>

        {selectedReward &&
          <div key={selectRewardKey}>
            <RewardModal
              title={selectedReward.title}
              subtitle={selectedReward.subtitle}
              description={selectedReward.description}
              icon={selectedReward.icon}
              isOpen={isModalOpen}
              onDidDismiss={() => setIsModalOpen(false)}
              link={selectedReward.link} />
          </div>
        }

      </IonContent>
    </IonPage>
  );
};

export default RewardsTab;
