import { IonContent, IonPage } from '@ionic/react';
import './BuzzTab.css';
import TapComponent from '../components/TapComponent/TapComponent'
import Points from '../components/Coins'
import EnergyAndBoost from '../components/EnergyAndBoost/EnergyAndBoost';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useEffect, useState } from 'react';
import { fetchProducts, getProductsStatus } from '../redux/productSlice';
import TutorialModal from '../components/TutorialModal';
import { TUTORIAL_MODAL_SHOWN_LS_KEY } from '../components/TutorialModal/TutorialModal';
import { useStorage } from '../contexts/StorageContext';
import NFTTeaserModal from '../components/NFTTeaserModal ';
import { useAnalytics } from '../contexts/AnalyticsContext';
import HBIcon from '../components/HBIcon';
import { IconNameEnum } from '../components/HBIcon/HBIcon';
import BingoModal from '../components/BingoModal';

const PRESENT_ANIMATION_CLICKED_KEY = 'present_animation_clicked_key';
const BINGO_ANIMATION_CLICKED_KEY = 'bingo_animation_clicked_key';


const BuzzTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const productsStatus = useAppSelector(getProductsStatus);

  const [showNFTTeaserModal, setShowNFTTeaserModal] = useState(false);
  const [showBingoModal, setShowBingoModal] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const { getItem, setItem, isInitialized } = useStorage();

  const [presentAnimation, setPresentAnimation] = useState('animate__zoomIn');
  const [bingoAnimation, setBingoAnimation] = useState('animate__zoomIn');


  const { trackEvent } = useAnalytics();

  const closeTutorialModal = async () => {
    setShowTutorialModal(false);
    await setItem(TUTORIAL_MODAL_SHOWN_LS_KEY, 'true');
  }

  useEffect(() => {
    const checkTutorialModalStatus = async () => {
      if (isInitialized) {
        try {
          const modalShown = await getItem(TUTORIAL_MODAL_SHOWN_LS_KEY);
          setShowTutorialModal(modalShown !== 'true');
        } catch (error) {
          console.error('Error checking tutorial modal status:', error);
          setShowTutorialModal(false); // Default to not showing if there's an error
        }

        const presentClicked = await getItem(PRESENT_ANIMATION_CLICKED_KEY) === 'true';
        if (!presentClicked) {
          setPresentAnimation('animate__pulse animate__infinite animate__slow');
        }

        const bingoClicked = await getItem(BINGO_ANIMATION_CLICKED_KEY) === 'true';
        if (!bingoClicked) {
          setBingoAnimation('animate__pulse animate__infinite animate__slow');
        }
      }
    };

    checkTutorialModalStatus();
  }, [getItem, isInitialized]);

  useEffect(() => {
    if (productsStatus === 'idle') {
      dispatch(fetchProducts());
    }
  }, [productsStatus])

  return (
    <IonPage>
      <IonContent>
        <ProfileHeader />
        <div className="hb-padding">
          <div className='w-full flex justify-center gap-4 mb-4'>
            <div onClick={() => {
              trackEvent('button', 'click', 'nft gift');
              setShowNFTTeaserModal(true)
            }}>
              <img width={64} className={`present-img animate__animated ${presentAnimation}`} src={`/present2.png`} />
            </div>

            <div onClick={() => {
              trackEvent('button', 'click', 'bingo');
              setShowBingoModal(true)
            }}>
              <HBIcon size={64} name={IconNameEnum.BingoTicket} className={`present-img animate__animated ${bingoAnimation}`} />
            </div>
          </div>
          <Points />

          <TapComponent />
          <EnergyAndBoost />
        </div>

        {isInitialized && <TutorialModal isOpen={showTutorialModal} onDidDismiss={closeTutorialModal} />}
        <NFTTeaserModal isOpen={showNFTTeaserModal} onDidDismiss={() => {
          setItem(PRESENT_ANIMATION_CLICKED_KEY, 'true');
          setPresentAnimation('');
          setShowNFTTeaserModal(false)
        }} />

        <BingoModal isOpen={showBingoModal} onDidDismiss={() => {
          setItem(BINGO_ANIMATION_CLICKED_KEY, 'true');
          setBingoAnimation('');
          setShowBingoModal(false)
        }} />

      </IonContent>
    </IonPage>
  );
};

export default BuzzTab;