// src/components/Icon/Icon.tsx

import React from 'react';
import RenewableEnergy from './icons/renewable-energy.svg';
import Tap from './icons/tap.svg';
import EnergyDrink from './icons/energy-drink.svg';
import Coin from './icons/coin.svg';
import Coins from './icons/coins.svg';
import TimeIsMoney from './icons/time-is-money.svg';
import Bee from './icons/bee.svg';
import Apiary from './icons/apiary.svg';
import Support from './icons/support.svg';
import Dot from './icons/dot.svg';
import Telegram from './icons/telegram.svg';
import Twitter from './icons/twitter.svg';
import InStock from './icons/in-stock.svg';
import Stopwatch from './icons/stopwatch.svg';
import Thunder from './icons/thunder.svg';
import Rocket from './icons/rocket.svg';
import Apiarist from './icons/apiarist.svg';
import CalendarDay from './icons/calendar-day.svg';
import Friends from './icons/friends.svg';
import Check from './icons/check.svg';
import Youtube from './icons/youtube.svg';
import QuestionMark from './icons/question-mark.svg';
import Ribbon from './icons/ribbon.svg';
import Medal from './icons/medal.svg';
import Award from './icons/award.svg';
import Cup from './icons/cup.svg';
import Nft from './icons/nft.svg';
import Beehive from './icons/beehive.svg';
import Airdrop from './icons/airdrop.svg';
import Megaphone from './icons/megaphone.svg';
import OpenGaming from './icons/open-gaming.svg';
import TeaFarm from './icons/tea-farm.png';
import One from './icons/one.svg';
import Two from './icons/two.svg';
import BingoTicket from './icons/bingo-ticket.svg';
import Orus from './icons/orus.jpg';
import Discord from './icons/discord.jpeg';
import BeraPaw from './icons/berapaw.png';

export enum IconNameEnum {
  RenewableEnergy = 'RenewableEnergy',
  Tap = 'Tap',
  EnergyDrink = 'EnergyDrink',
  Coin = 'Coin',
  Coins = 'Coins',
  TimeIsMoney = 'TimeIsMoney',
  Bee = 'Bee',
  Apiary = 'Apiary',
  Support = 'Support',
  Dot = 'Dot',
  Telegram = 'Telegram',
  Twitter = 'Twitter',
  InStock = 'InStock',
  Stopwatch = 'Stopwatch',
  Thunder = 'Thunder',
  Rocket = 'Rocket',
  Apiarist = 'Apiarist',
  CalendarDay = 'CalendarDay',
  Friends = 'Friends',
  Check = 'Check',
  Youtube = 'Youtube',
  QuestionMark = 'QuestionMark',
  Ribbon = 'Ribbon',
  Medal = 'Medal',
  Award = 'Award',
  Cup = 'Cup',
  Nft = 'Nft',
  Beehive = 'Beehive',
  Airdrop = 'Airdrop',
  Megaphone = 'Megaphone',
  OpenGaming = 'OpenGaming',
  TeaFarm = 'TeaFarm',
  One = 'One',
  Two = 'Two',
  BingoTicket = 'BingoTicket',
  Orus = 'Orus',
  Discord = 'Discord',
  BeraPaw = 'BeraPaw',
}

interface IconProps {
  name: IconNameEnum;
  size?: number;
  customStyle?: object;
  className?: string;
}

const iconMap = {
  RenewableEnergy,
  Tap,
  EnergyDrink,
  Coin,
  TimeIsMoney,
  Bee,
  Apiary,
  Support,
  Dot,
  Telegram,
  Twitter,
  InStock,
  Stopwatch,
  Thunder,
  Rocket,
  Apiarist,
  Coins,
  CalendarDay,
  Friends,
  Check,
  Youtube,
  QuestionMark,
  Ribbon,
  Medal,
  Award,
  Cup,
  Nft,
  Beehive,
  Airdrop,
  Megaphone,
  OpenGaming,
  TeaFarm,
  One,
  Two,
  BingoTicket,
  Orus,
  Discord,
  BeraPaw,
};

const Icon: React.FC<IconProps> = ({ name, size = 16, customStyle = {}, className = '' }) => {
  const SvgIcon = iconMap[name];

  if (!SvgIcon) {
    return null;
  }

  const style = {
    ...customStyle,
    ...{ width: `${size}px` }
  }

  return <img
    className={className}
    src={SvgIcon}
    alt="Coin"
    style={style}
/>
};

export default Icon;
